import React from "react"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"


export default () => {
  const data = useStaticQuery(graphql`
    {
      allFile(
        filter: { relativeDirectory: { eq: "dersler" } }
        sort: { fields: childMarkdownRemark___frontmatter___order, order: ASC }
      ) {
        edges {
          node {
            childMarkdownRemark {
              excerpt(pruneLength: 150)
              fields {
                slug
              }
              frontmatter {
                description
                BlogImageAlt
                date(formatString: "LLLL", locale: "TR")
                BlogImage 
                title
              }
            }
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO title="Dersler" />
      <h1>Dersler</h1>
      <div className="container mt-5">
        <div className="row justify-content-center">
          <div className="col-12 text-center">
            <div className="section-title mb-4 pb-2">
              <h4 className="title mb-4">Popüler Dersler</h4>
            </div>
          </div>
        </div>
        <div className="row">
          {data.allFile.edges.map(({ node }) => (
            <div className="col-lg-4 col-md-6 col-12 mt-4 pt-2 pb-2">
              <Link
                to={node.childMarkdownRemark.fields.slug}
                className="text-white h6 preview"
              >
                <div className="courses-desc rounded border position-relative overflow-hidden">
                  <div className="position-relative d-block overflow-hidden">
                   {node.childMarkdownRemark.frontmatter.BlogImage ?
                    <img
                    className="img-fluid rounded-top mx-auto courses-box"
                    src={node.childMarkdownRemark.frontmatter.BlogImage}
                    alt={node.childMarkdownRemark.frontmatter.BlogImageAlt}
                    />
                    :
                    null} 
                    <div className="overlay-work" />
                  </div>
                  <div className="content p-3">
                    <h5>
                      <Link
                        to={node.childMarkdownRemark.fields.slug}
                        className="title text-dark"
                      >
                        {node.childMarkdownRemark.frontmatter.title}
                      </Link>
                    </h5>
                    <p>
                      <p className="title text-dark">
                        {node.childMarkdownRemark.frontmatter.description}
                      </p>
                    </p>
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
      <div className="col-12 mt-5 mb-5"></div>
    </Layout>
  )
}
